import logo from './logo.svg';
import './App.css';

import {useState,useEffect} from 'react';

function App() {

  const [toggle, setToggle] = useState(true)
  const [data,setData]=useState([]);

  const getData=()=>{
    fetch('data.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setData(myJson)
      });
  }

  useEffect(()=>{
    getData()
  },[])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Algorithmic Trading with MQL5 Programming (C++, Python)
        </p>

        <table className="App-table">
          <tr>
            <td className="App-td">MQL5</td>
            <td className="App-td">MetaQuotes Language 5 (MQL5) is a high-level language designed for developing technical indicators, trading robots and utility applications, which automate financial trading.</td>
          </tr>
          <tr>
            <td className="App-td">M15 Chart</td>
            <td className="App-td"><a target="_blank" href="https://trading1501.byethost8.com/M15charts.php">https://trading1501.byethost8.com/M15charts.php</a></td>
          </tr>
          <tr>
            <td className="App-td">H4 Chart</td>
            <td className="App-td"><a target="_blank" href="https://trading1501.byethost8.com/H4charts.php">https://trading1501.byethost8.com/H4charts.php</a></td>
          </tr>
          <tr>
            <td className="App-td">Daily Chart</td>
            <td className="App-td"><a target="_blank" href="https://trading1501.byethost8.com/D1charts.php">https://trading1501.byethost8.com/D1charts.php</a></td>
          </tr>
        </table>


        {/*
          data && data.charts && data.charts.length>0 && data.charts.map((item,index)=>
          
          <div className="App-div" key={index}>
            <div>
                {index+1} ) {item.pair}
              
                <button className="App-button"
                  onClick={() => setToggle(!toggle)} 
                >
                  { toggle? 'Daily' : 'H4' }
                </button>
                
            </div>
            
            <img className="App-img" alt={index+"image"} src={ toggle? './charts/'+item.pair+'Daily.png' : './charts/'+item.pair+'H4.png' } />
            
          </div>

          )
        */}
        {/*
        <p>
          &copy; Trading1501.com
        </p>
        */}
        <p>
          <span>&#9993;</span> ai@trading1501.com
        </p>

      </header>
    </div>
  );
}

export default App;
